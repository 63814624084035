import React from "react";
import Bottom from "./Bottom.jsx";



function Content(){
    return(
        <div>
                    <title>Content Writing | Zutech</title>

<section className="breadcrumb-bnr">
        <img src={process.env.PUBLIC_URL+"./images/wordpress-development.png"} alt="Banner"></img>
    <div className="breadcrumb-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>
                        Content Writing
                    </h1>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="#/Home">Home</a></li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item"><a href="#">Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>





<div className="container">
    <div className="row">
        <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"./images/content-writing.png"} alt="img"></img>
        </div>
        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div className="section-title">
            
                <h1>Useful, Enjoyable, and Inspired -   <span>Innovative Content for  </span> Smarter Digital Marketing</h1>
                
                    <img  src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <p> Inform, educate and entertain your target audience with relevant, engaging, interesting, and useful content. Our high-quality and well-qualified writers develop your brands’ voice and tone using strategically crafted messaging and proven best practices. We cover a diverse range of industries and offer a hands-on service to help you with every aspect of your content marketing needs. 
<br></br>
At Zutech, all content written is professionally proofread and edited, and you can also request revisions. Whether you’re looking for blogs, articles, copywriting, technical writing, social media posts, emails, ebooks, case studies, press releases, or testimonials, we have got you covered. We help businesses across the world to create compelling content while following SEO best practices to ensure your content is findable and drives potential visitors from search. 


               </p>


            <div className="space" style={{height: "30px"}}></div>

            <div className="zutech-btn">
                <a href="#/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>






<div className="space" style={{height: "115px"}}></div>











<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img 
                    src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                <i class="fa fa-quote-right"></i>

                    <h5> Blog Writing </h5>
                    <p className="websites">If you’re a small business, SEO and digital marketing agency, or even an enterprise-level company, Zutech’s blog writing services will help you and your clients drive traffic and delight readers. Our expert writing team can create engaging, SEO-driven blogs your website needs and your audience deserves. We even provide you with suggested tags, titles, and meta descriptions. </p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                <i className="fa fa-edit"></i>
                    <h5>Article Writing</h5>
                    <p className="websites">
                    Increase brand visibility, generate more leads, diversify audience engagement, and establish your expertise and trust with our high-quality and captivating article writing service. Whether it’s a guest article, ad copy, or ghostwriting, our writers have the talent and skills to write any type of article. 
</p>
</div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
            <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{heigminHeightht:"420px"}}>
                    <i className="fa fa-users"></i>
                    <h5>Social Media Content</h5>
                    <p className="websites">
                    Keep your social media profiles buzzing with engagement and positive activity. Build your online presence, drive brand awareness and attract and retain customers with our one-of-a-kind social media content service. We help you to create perfectly written posts, infographics, testimonials & reviews, quotes, education content, guides, and more. </p>                </div>
            </a>
        </div>


    </div>
</div>
<Bottom></Bottom>

       
        </div>
    )

}
export default Content;