import React from "react";
import Bottom from "./Bottom.jsx";

function Designing(){
    return(
        <div>
                    <title>Web Designing | Zutech</title>

<section className="breadcrumb-bnr">
<img src={process.env.PUBLIC_URL+"./images/wordpress-development.png"} alt="Banner"></img>
<div className="breadcrumb-content">
   <div className="container">
       <div className="row">
           <div className="col-md-12 text-center">
               <h1> Web Designing </h1>
               <ul className="list-inline">
                   <li className="list-inline-item"><a href="#/Home">Home</a></li>
                   <li className="list-inline-item">/</li>
                   <li className="list-inline-item"><a href="#">Services</a></li>
               </ul>
           </div>
       </div>
   </div>
</div>
</section>







<div className="container">
<div className="row">
   <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
      
           <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"./images/student.jpg"} alt="img"></img>
   </div>
   <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
       <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
       <div className="section-title">
           
           <h1>Web  <span>Designing</span></h1>
           
               <img  src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
       </div>
       <p>
       Want a website that converts visitors into customers? We can help you create a website that can grab the user's attention and increase your sales. Our expert web designers work closely with the clients to ensure the site truly represents what their brand is. If you are looking for an innovative and highly engaging website that can offer your clients an immersive digital web experience. Contact us to know more.
       </p>


       <div className="space" style={{height: "30px"}}></div>

       <div className="zutech-btn">
           <a href="#/Contact-us">Contact Us</a>
       </div>
   </div>
</div>
</div>

<div className="space" style={{height: "115px"}}></div>








<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
            <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                    <i className="fa fa-desktop"></i> 
                    <h5> Web App Design</h5>
                    <p class="websites">Want to design a web app that amplifies user engagement? Our user-centric and feature-rich app web app design services are intuitive and perform exceedingly well. All our experts are powered with experience and tools that can help in crafting impressive web app designs.</p> </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                     <i className="fa fa-mobile"></i> 
                     <h5>
Website Design
</h5>
               <p class="websites">Searching for ways to woo your customers? Hire our website design services and give your business the best makeover. We create websites that provide a seamless user interface and easy accessibility that can lure more customers. Check out our portfolio to surf through our marvelous work.</p>
          
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                     <i className="fa fa-shield"></i> 
                     <h5>E-Commerce Web Design
</h5>
               <p className="websites">Convert your casual visitors into your regular customers with our astounding e-commerce web design services. If you are struggling hard to make that possible sale on your site, our optimized web design services can help you in leaving a positive impression on the visitors and help in generating revenues.</p>
         
                </div>
            </a>
        </div>
        <br></br><br></br>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                     <i className="fa fa-tasks"></i> 
                     <h5>Customized Web Design</h5>
               <p className="websites">Do you want a personalized website design for your brand? At Zutech, we provide custom web design services to brands that help them to turn the audience to entrust their brand. From planning to executing the right web design, we cover you all with only the best.</p>
           
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                <i class="fa fa-globe"></i>
                <h5>Website Redesign</h5>
               <p className="websites">Is your old website not getting enough visitors? Our bonafide web designers can help you revamp your old website and give it a modern outlook for your business. Our creative website redesign services will work in your business favor and lead to more conversions.</p>
           
                </div>
            </a>
        </div>
       </div>
</div>


<Bottom></Bottom>


</div>
        
    )
}
export default Designing;