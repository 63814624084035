import React from "react";
import Bottom from "./Bottom.jsx";

function ITServices(){
    return(
        <div>
                            <title>IT Services | Zutech</title>

<section className="breadcrumb-bnr">
        <img src={process.env.PUBLIC_URL+"/images/wordpress-development.png"} alt="Banner"></img>
    <div className="breadcrumb-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>IT Services </h1>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="/Home">Home</a></li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item"><a href="#">Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>







<div className="container">
    <div className="row">
        <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"/images/student.jpg"} alt="img"></img>
        </div>
        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div className="section-title">
            
                <h1>Empower Your Business   <span> With An Expert Suite Of </span>IT Services</h1>
                
                    <img  src={process.env.PUBLIC_URL+"/zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <p>
            Are you planning to enter the world of business? Or, planning to expand your business? If yes, then you have landed at the right place! We at Zutech, have a team of highly experienced IT professionals who have worked on more than 500+ projects. We strive to provide dedicated IT services that can help you earn better returns on investments. Our tailor-made and flexible IT services help in catering to unique business needs. Get in touch with our professionals to know the details.
                        </p>


            <div className="space" style={{height: "30px"}}></div>

            <div className="zutech-btn">
                <a href="/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>




<div className="space" style={{height: "115px"}}></div>











<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img src={process.env.PUBLIC_URL+"/zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
            <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                     <i className="fa fa-share-square"></i> 
                    <h5>Share Point Management</h5>
                    <p className="websites">Seeking a better business perspective? We can help! We offer a versatile management tool that helps you sort your business activities. This allows businesses to manage the organization’s functions all at one place in a defined way. So, if you are facing trouble managing the work, then our IT solutions might come in handy.</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                     <i className="fa fa-shield"></i> 
                    <h5>Resources And Security</h5>
                    <p>Finding the right business resources can be daunting. We can help you in gathering the right resources and tools needed to fulfill your business requirements. We ensure highly secured and robust solutions that can efficiently manage your day-to-day activities.

                    </p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                     <i className="fa fa-tasks"></i> 
                    <h5>Assets Management</h5>
                    <p>Worried about managing the business assets? With our dedicated services, you can easily manage your business assets all in one place. No matter what your business is, our powerful services can help manage your assets by keeping them secure.</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                     <i className="fa fa-desktop"></i> 
                    <h5>Desktop Support</h5>
                    <p className="websites">Our dedicated desktop support ensures your system stays in place. With our high-tech services on your side, you won’t ever have to face any problem managing your system. So, no matter how hard the trouble is, our experts can tackle that in the right way.</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                     <i className="fa fa-id-badge"></i> 
                    <h5>Software License Management</h5>
                    <p>
We procure and protect your IT systems with software license management. Our powerful yet affordable services ensure the long endurance of the software and systems. So, choose the best for your system by joining hands with the best.
</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"350px"}}>
                <i class="fa fa-sitemap"></i>
                    <h5>IT Supply</h5>
                    <p>We are a one-stop shop for all your IT needs. No matter what your IT needs are, our experts are here to help in catering to all. If you want to do right for your business, then this is the time to opt for our services and gain an edge over your competitors.</p>
                </div>
            </a>
        </div>
    </div>
</div>


<Bottom></Bottom>


        </div>
    )
}
export default ITServices;