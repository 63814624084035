import React ,{useState}from "react";
import Bottom from "./Bottom.jsx";


function Contact() {
    const [userErr,setUserErr] = useState(true);
    const [PhoneErr,setphoneErr] = useState(true);
    const [emailErr,setemailErr] = useState(true);
    const [msgErr,setmsgErr] = useState(true);
    const [subErr,setsubErr] = useState(true);
    const [dis,disa] = useState(true);


    function Namehandle(e){
        let item=e.target.value;
        if(item.length<3|| item.includes("www") ){
            setUserErr(true);
            
        }
       
        else{
            setUserErr(false);
            disa(false);
        }
    }
    function subhandle(e){
        let item=e.target.value;
        if(item.includes("@")){
            setsubErr(true);
        }
       
        else{
            setsubErr(false);
        }
    }
    function msghandle(e){
        let item=e.target.value;
        if(item.includes(".com") || item.includes("www")){
            setmsgErr(true);
            disa(true);
        }
       
        else{
            setmsgErr(false);
            disa(false);
        }
    }
    function emailhandle(e){
        let item=e.target.value;
        if(item.includes("@") ){
            setemailErr(false);
        }
       
        else{
            setemailErr(true);
        }
    }
    function phonehandle(e){
        let item=e.target.value;
        if(isNaN(item) || item.length<10 ){
            setphoneErr(true);
        }
        else{
            setphoneErr(false);
        }
    }

 
 
        return (
            <div>
                <title>Contact Us | Zutech</title>
                <section class="breadcrumb-bnr" style={{ height: "394px" }}>

                    <img src={process.env.PUBLIC_URL + "./images/contactus-1.jpg"} alt="Banner"></img>
                    <div class="breadcrumb-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <h1>Contact Us</h1>
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><a href="#/Home">Home</a></li>
                                        <li class="list-inline-item">/</li>
                                        <li class="list-inline-item"><a href="#">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="contact" className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 wow fadeInLeft" data-wow-duration="2.5s" >
                            <div className="section-title">
                                <span>Contact US</span>
                                <h1>Don’t feel hesitate to <span>contact</span>Us</h1>

                                <img src={process.env.PUBLIC_URL + "./dot-bluecolor.png"} alt="img"></img>
                            </div>

                            <div className="space" style={{ height: "50px" }}></div>
                            <div className="row">
                        <div className="col-lg-12 col-sm-12 wow fadeInLeft" data-wow-duration="2.5s" >
                        

                            <form action="https://emails.zutech.com.au/TonoContact/Form"  id="contact-form" method="post">
                            <div className="row contact-form">
                                    <div className="col-md-6 form-group">
                                        <input type="text" className="form-control mb0" onChange={Namehandle} id="Name" placeholder="Name" name="name" ></input>
                                        {userErr?<span className="msg-error">Enter valid Name</span>:""}
                                    </div>
                                    <div className="col-md-6 form-group ">
                                        <input type="Email" className="form-control mb0"  onChange={emailhandle}  id="Email" placeholder="Email" name="email"  ></input>
                                        {emailErr?<span className="msg-error">Enter valid Email </span>:""}

                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="text" className="form-control mb0"  onChange={phonehandle} placeholder="Contact" name="CountryPassport"></input>
                                        {PhoneErr?<span className="msg-error">Enter valid Phone number</span>:""}

                                    </div>
                                    <div className="col-md-6 form-group" >
                                    <div class="form-group marb0">
                                     <select style={{height:"3rem"}} class="form-control" placeholder="Subject" id="Intrestedin"  onChange={subhandle}   name="Intrestedin"  >
                                       <option>Select Service</option>
                                       <option>Digital marketing</option>
                                       <option>Web Development</option>
                                       <option>IT Services</option>
                                       <option>Search Engine Optimisation</option>
                                       <option>Web Designing</option>
                                       <option>Content Writing</option>
                                     </select>
                                   </div>
                                    {subErr?<span className="msg-error">Select Service </span>:""}
                                    </div>
                                    <div className="col-md-12 form-group" >
                                        <textarea class="form-control mb0"  onChange={msghandle}  placeholder="Message" id="Message" rows="3" name="message" ></textarea>
                                        {msgErr?<span className="msg-error">Please Enter Message</span>:""}</div>
                                    <div class="zutech-btn col-md-12 form-group">
                                    <div className="col-md-12 popub">
                                    {dis?<input class="contact-btn" disabled value="Send Now"  type="submit" id="btnsubmit" name="submit-form"></input>:<input class="contact-btn"  value="Send Now"  type="submit" id="btnsubmit" name="submit-form"></input>}
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>

                      
                    </div>


                        </div>

                        <div className="col-lg-6 d-sm-none d-xs-none d-lg-block wow fadeInRight" data-wow-duration="2.5s" >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.7821952413997!2d144.9605370154119!3d-37.818570342091675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b3166baa79%3A0xe846f57879d9d74e!2sSuite%2010%2C%20Level%207%2F2%20Queen%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1600936293102!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
                <Bottom></Bottom>
            </div>
        )
    
}
export default Contact;