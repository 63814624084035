import React from "react";
import Bottom from "./Bottom.jsx";

function Webdevlopment(){
    return(
        <div>
            <title>Web Development | Zutech</title>

<section className="breadcrumb-bnr">
        <img src={process.env.PUBLIC_URL+"/images/wordpress-development.png"} alt="Banner"></img>
    <div className="breadcrumb-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>Web Development </h1>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="#/Home">Home</a></li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item"><a href="#">Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


<div className="container">
    <div className="row">
        <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"/images/web-development.png"} alt="img"></img>
        </div>
        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div className="section-title">
            
                <h1>Web  <span> Development </span></h1>
                
                    <img  src={process.env.PUBLIC_URL+"/images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <p>Want to create an impressive website for your business? Or are you planning to revamp your business website? We are here to the rescue! Zutech can help you create engaging websites that will accelerate your business growth. We have an in-house team of expert web developers who hold expertise in creating immersive web experiences. Our cutting-edge websites not only help in creating beautiful websites but also perform exceptionally well. Let us know your requirements and get started

            </p>


            <div className="space" style={{height: "30px"}}></div>

            <div className="zutech-btn">
                <a href="#/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>



<div ClassName="space" style={{height:"115px"}}></div>





<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img src={process.env.PUBLIC_URL+"/zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
            <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                    <i className="fa fa-share-square"></i> 
                    <h5> Web Designing</h5>
                    <p ClassName="websites">Grow your business online with modern website design services. If you strive to turn your casual visitors into your loyal customers, then lure them with an attractive website. We design websites keeping in mind the unique business needs that can help in enhancing user experience but also provide you with generating more traffic.</p> </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                     <i className="fa fa-tasks"></i> 
                     <h5>WordPress</h5>
                    <p ClassName="websites">
Planning to invest in a WordPress website? Join hands with the best! Zutech provides impeccable WordPress development services that can give your business a modern outlook. Our bonafide developers are known for creating user-friendly websites that can make your business reach the target audiences efficiently. Contact us to know more.
</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                <i class="fa fa-edit"></i>
                <h5>                        Web Development
</h5>
                    <p ClassName="websites">
                    Tired of finding the right web development services for your business? Join hands with Zutech. We have a talented team of web developers who hold years of experience in providing dedicated web development services to clients across the globe. If you plan to surge your online visitors, our impressive web development services will fulfill your dreams.
                    </p>
                </div>
            </a>
        </div>
       </div>
</div>




<Bottom></Bottom>




        </div>
    )
}

export default Webdevlopment;