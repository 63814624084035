import React,{useState} from "react";
import Bottom from "./Bottom.jsx";

function Home() {
    const [userErr,setUserErr] = useState(true);
    const [PhoneErr,setphoneErr] = useState(true);
    const [emailErr,setemailErr] = useState(true);
    const [msgErr,setmsgErr] = useState(true);
    const [subErr,setsubErr] = useState(true);
    const [dis,disa] = useState(true);


    function Namehandle(e){
        let item=e.target.value;
        if(item.length<3|| item.includes("www") ){
            setUserErr(true);
            
        }
       
        else{
            setUserErr(false);
            disa(false);
        }
    }
    function subhandle(e){
        let item=e.target.value;
        if(item.includes("@")){
            setsubErr(true);
        }
       
        else{
            setsubErr(false);
        }
    }
    function msghandle(e){
        let item=e.target.value;
        if(item.includes(".com") || item.includes("www")){
            setmsgErr(true);
            disa(true);
        }
       
        else{
            setmsgErr(false);
            disa(false);
        }
    }
    function emailhandle(e){
        let item=e.target.value;
        if(item.includes("@") ){
            setemailErr(false);
        }
       
        else{
            setemailErr(true);
        }
    }
    function phonehandle(e){
        let item=e.target.value;
        if(isNaN(item) || item.length<10 ){
            setphoneErr(true);
        }
        else{
            setphoneErr(false);
        }
    }

 
   
    return(
        <div>
                            <title>Home | Zutech</title>

<div>
<section id="banner" className="banner">
<div className="container">
<div className="row">
<div className="col-md-12 wow fadeInLeft" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInLeft"}}>
<div className="bnr-social">
                  <ul className="list-inline left-social-icon" style={{marginTop:"77px"}}>
                      <li className="list-inline-item"><a href="https://www.facebook.com/Zutechcorp"><i className="fa fa-fw fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="https://www.instagram.com/zutech_au/"><i className="fa fa-fw fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-fw fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="https://www.linkedin.com/company/zutech-corp/"><i className="fa fa-fw fa-linkedin"></i></a></li>
                  </ul>
              </div>
              <div className="side">
              <h1>Welcome to Zutech <br></br>Corp.</h1>
              <p>Transform your business presence with our bespoke IT services.</p>
              <div className="bnr-btn">
                  <ul className="list-inline ">
                      <li className="list-inline-item"> <div className="zutech-btn" data-popup-open="popup-1" style={{margintop: "px;"}}>
              <a >Contact Us</a>
          </div></li>
                      <li className="list-inline-item">
                      <div className="zutech-btn" style={{margintop: "px;"}}>
              <a href="#/About-us">Learn more</a>
          </div></li>
                  </ul>
              </div>
 
<div class="popup" data-popup="popup-1">
    <div class="popup-inner">
        <h2 className="col-md-12 head">Get in Touch.</h2>
       <div class="popup-scroll">      <div id="contact" className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 wow fadeInLeft" data-wow-duration="2.5s" >
                        

                            <form action="http://localhost:2417//TonoContact/Form" id="contact-form" method="post">
                            <div className="row contact-form">
                                    <div className="col-md-6 form-group">
                                        <input type="text" className="form-control mb0" onChange={Namehandle} id="Name" placeholder="Name" name="name" ></input>
                                        {userErr?<span className="msg-error">Enter valid Name</span>:""}
                                    </div>
                                    <div className="col-md-6 form-group ">
                                        <input type="Email" className="form-control mb0"  onChange={emailhandle}  id="Email" placeholder="Email" name="email"  ></input>
                                        {emailErr?<span className="msg-error">Enter valid Email </span>:""}

                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="text" className="form-control mb0"  onChange={phonehandle} placeholder="Contact" name="CountryPassport"></input>
                                        {PhoneErr?<span className="msg-error">Enter valid Phone number</span>:""}

                                    </div>
                                    <div className="col-md-6 form-group" >
                                    <div class="form-group marb0">
                                     <select style={{height:"3rem"}} class="form-control" placeholder="Subject" id="Intrestedin"  onChange={subhandle}   name="Intrestedin"  >
                                       <option>Select Service</option>
                                       <option>Digital marketing</option>
                                       <option>Web Development</option>
                                       <option>IT Services</option>
                                       <option>Search Engine Optimisation</option>
                                       <option>Web Designing</option>
                                       <option>Content Writing</option>
                                     </select>
                                   </div>
                                    {subErr?<span className="msg-error">Select Service </span>:""}
                                    </div>
                                    <div className="col-md-12 form-group" >
                                        <textarea class="form-control mb0"  onChange={msghandle}  placeholder="Message" id="Message" rows="3" name="message" ></textarea>
                                        {msgErr?<span className="msg-error">Please Enter Message</span>:""}</div>
                                    <div class="zutech-btn col-md-12 form-group">
                                    <div className="col-md-12 popub">
                                    {dis?<input class="contact-btn" disabled value="Send Now"  type="submit" id="btnsubmit" name="submit-form"></input>:<input class="contact-btn"  value="Send Now"  type="submit" id="btnsubmit" name="submit-form"></input>}
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>

                      
                    </div>
                </div></div>
        <a class="popup-close" data-popup-close="popup-1" href="#">x</a>
    </div>
</div>
             
          
              </div>
              <div className="bnr-left-sape wow fadeInLeft" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInLeft"}}>
                  <img src="" alt="shape"></img>
              </div>
              <div className="bnr-shape-img-2" ></div>

</div>
    </div>
    </div>
</section>

<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
                <img className="img-fluid" src={process.env.PUBLIC_URL+"/dot-bluecolor.png"} alt="img"></img>
               
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>
            <a href="#/Web-Designing" style={{color: "#212529"}}>
            <div className="service-item" style={{minheight: "400px"}}>
                    <i className="fa fa-desktop"></i>

                    <h5> Web Designing</h5>
                    <p className="websites">
                    Paint your website with immersive web designs. We have a talented in-house team of Australian web designers who can transform your business look with intuitive designs and compelling content.</p>
                </div>
            </a>
        </div>

        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>        <a href="#/IT-Services"  style={{color: "#212529"}}>
        
                <div className="service-item" style={{minheight: "400px"}}>
                    <i className="fa fa-cogs"></i>
                    <h5>IT services</h5>
                    <p className="websites">
                    Get an edge over your competitors with our core IT services. Our experienced IT experts can help in taking away your business vows and provide you with impressive business solutions.</p>                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>        
        <a href="#/Digital-Marketing" style={{color: "#212529"}}>
            <div className="service-item" style={{minheight: "400px"}}>
                    <i className="fa fa-globe"></i>
                    <h5>Digital marketing</h5>
                    <p className="websites">
                    Creating a buzz for your business online with our expert digital marketing services in Australia. Our marketing experts are well-versed with the latest trends online and look for ways that can allure your target audiences.</p>                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>       
         <a href="#/Web-Devlopment" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight: "506px",margintop:"32px"}}>

                    <i className="fa fa-database"></i>

                    <h5> Web development</h5>
                    <p className="websites">
                    Give your business a makeover with our top-notch website development services. Our professional web developers hold extensive experience in developing both static and dynamic websites for businesses. If you are looking for robust web solutions, then you have landed in the right place.
                       </p>

                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>       
         <a href="#/Search-Engine-Optimisation" style={{color: "#212529"}}>
            <div className="service-item" style={{minheight: "506px",margintop:"32px"}}>

                    <i className="fa fa-search"></i>

                    <h5> Search Engine Optimisation</h5>
                    <p className="websites">
                      Enhance your brand's visibility with Zutech Corp SEO services. Our unique SEO services help in ranking your business on #1 of the search engines. With us, you are sure to be the hero in the online market with blockbuster SEO results. </p>

                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInUp"}}>     
           <a href="#/Content-Writing" style={{color: "#212529"}}>
            <div className="service-item" style={{minheight: "506px",margintop:"32px"}}>
                    <i className="fa fa-edit"></i>

                    <h5> Content Writing</h5>
                    <p className="websites" style={{paddingbottom:"26px"}}>
                        
Let the words paint your business on a beauty path with our creative content writing services.  Our bonafide content writers craft masterpiece content that can attract custom audiences and boost conversions.
      
                                       </p>

                </div>
            </a>
        </div>
    </div>
</div>
   
<div className="space" style={{height: "130px"}}></div>
<div className="container">
  <div className="row">
      <div className="col-md-6 text-center wow fadeInLeft animated" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInLeft"}}>
          <img className="img-fluid img-shadow" data-tilt="" src={process.env.PUBLIC_URL+"/images/img-3.png"}
           alt="img"></img>
      </div>
      <div className="col-md-6 wow fadeInRight animated" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s", animationname: "fadeInLeft", paddingleft: "60px"}}   >
          <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
          <div className="section-title">
              <h1 style={{ margintop:"-9px;"}}> <span> Our Vision</span> </h1>
                  <img className="img-fluid" src={process.env.PUBLIC_URL+"/dot-bluecolor.png"} alt="img"></img>
          </div>
          <p className="text-left" >
          In this tech-age, Businesses struggle to stand out of the crowd. Zutech Corp IT Solutions in Australia provides robust business solutions that can help businesses grow on a global level. From website designing to web development, digital marketing, content marketing, social media marketing, and more we cover all for your business with the best IT solutions in Australia. What makes us different from others is our unique business approach that helps brands leave a lasting impression on the audiences. Our customers are our pride and topmost priority. We believe in building long-term relationships with our clients and stay 24/7 available for our clients to cater to their business needs.
          </p>
   


          <div className="zutech-btn" style={{margintop: "95px;"}}>
              <a href="#/Contact-us">Need Help..</a>
          </div>
      </div>
  </div>
</div>
<div className="space" style={{height: "130px"}}></div>
<div id="features" className="container">
  <div className="row">
      <div className="col-lg-5 wow fadeInLeft" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s"}}>
          <div className="section-title">

              <h1>Why <span>Choose</span>  Us?</h1>
              <img src={process.env.PUBLIC_URL+"/dot-bluecolor.png"} alt="img"></img>
          </div>
          <div className="space" style={{height: "50px"}}></div>
          <div className="feature-item wow bounceInUp" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s"}}>
              <div className="feature-item-icon">
              <i className="fa fa-signal"></i>
              </div>
              <div className="feature-item-content">
                  <h5>Quality That Matters
</h5>
                  <p>We are backed with a team of dedicated experts who ensure quality at all times. Our tailored IT services help businesses achieve their goals.</p>
              </div>
          </div>
          <div className="feature-item wow bounceInUp" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s"}}>
          <div className="feature-item-icon">
          <i className="fa fa-rocket"></i>
              </div>
              <div className="feature-item-content">
                  <h5>Customer Satisfaction </h5>
                  <p style={{overflow: "hidden"}}>
Ensuring customer satisfaction is our main goal. All our IT solutions in Melbourne focus on providing 100% satisfaction results.</p>

              </div>
          </div>
          <div className="feature-item wow bounceInUp" data-wow-duration="3.5s" style={{visibility: "visible", animationduration: "2.5s"}}>
              <div className="feature-item-icon">
              <i className="fa fa-phone"></i>
              </div>
              <div className="feature-item-content">
                  <h5>Support Just A Call Away</h5>
                  <p>No matter at what time you request our services, our dedicated customer support will stay available at all times. Try us!. </p>
              </div>
          </div>

      </div>
      <div className="col-xl-5 col-lg-6 offset-xl-1 text-center wow fadeInRight" data-wow-duration="2.5s" style={{visibility: "visible", animationduration: "2.5s"}}>
        
              <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"/images/img-2.png"}  alt="img"></img>

          <div className="pic-right-bottom">
              <img 
              src={process.env.PUBLIC_URL+"/zutech/dot-squire.png"} alt="Img"></img>
          </div>

      </div>
  </div>
</div>
<section>
<div className="space" style={{height: "130px"}}></div>

  <div className="demo">
      <div className="container">
          <div className="row">
              <div className="col-md-4 col-sm-6">
                  <div className="pricingTable">
                      <div className="pricing-content">
                          <div className="pricingTable-header">
                              <h3 className="title">Web Development</h3>
                          </div>
                          <ul className="content-list">
                             
                              <li> <img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> 3 Months Free hosting</li>
                              <li> <img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Asp.Net Applications</li>
                              <li> <img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Wordpress</li>
                              <li> <img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> One Page Applications</li>
                              <li> <img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Free Subdomain </li>
                             
                            
                            
                            
                            
                            
                               
                          </ul>
                          <div className="price-value">
                              <span className="amount">$199.00</span>
                              <span v="duration">start from</span>
                          </div>
                          <div className="pricingTable-signup">
                              <a href="#/Contact-us">Sign Up</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-4 col-sm-6">
                  <div className="pricingTable blue">
                      <div className="pricing-content">
                          <div className="pricingTable-header">
                              <h3 className="title">Search Engine Optimisation</h3>
                              <br />
                          </div>
                          <ul className="content-list">
                              <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Article Submissions</li>
                              <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Guest Posting</li>
                              <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Blogs Marketing</li>
                              <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Backlinks,</li>
                              <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Improve SERP Features</li>

                          </ul>
                            <div className="price-value">
                              <span className="amount">$399.00</span>
                              <span className="duration">start from</span>
                          </div>
                          <div className="pricingTable-signup">
                              <a href="#/Contact-us">Sign Up</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-4 col-sm-6">
                  <div className="pricingTable">
                      <div className="pricing-content">
                          <div className="pricingTable-header">
                              <h3 className="title">Digital<br></br> Marketing</h3>
                          </div>
                          <ul className="content-list">
                          <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Facebook Campaigning</li>
                          <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Email Marketing</li>
                          <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> SMS Marketing</li>
                          <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Content Marketing</li>
                          <li><img src={process.env.PUBLIC_URL+"/images/checked.png"} style={{width: "21px",marginright: "10px" }}></img> Graphic Designing and Ads</li>

                          </ul>
                          <div className="price-value">
                              <span className="amount">$299.00</span>
                              <span className="duration">start from</span>
                          </div>
                          <div className="pricingTable-signup">
                              <a href="#/Contact-us">Sign Up</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<div className="space" style={{height: "130px"}}></div>
<div className="container">
<div id="portfolio">
    <div className="row">
        <div className="col-md-12">
            <div className="section-title text-center">

                <h1>Latest portfolio</h1>
                
                    <img 
                    src={process.env.PUBLIC_URL+"/zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>

        <div className="col-sm-12">
            <div className="portfolio-filter">
                <ul className="list-inline">
                    <li className="select-cat list-inline-item" data-filter="*">All</li>
                    <li className="list-inline-item" data-filter=".design">Web Development</li>
                    <li className="list-inline-item" data-filter=".web-developpment">Digital Marketing</li>
                    <li className="list-inline-item" data-filter=".digital-marketing">SEO</li>
                </ul>
            </div>
        </div>
        <div className="isotope_items">
         
            <div data-tilt className="single_item portfolio-big-img design">
                
                    <img className="img-fluid"
                    src={process.env.PUBLIC_URL+"/images/ss.png"} alt="Img"></img>
                <div className="single_item_content">
                    <a href={process.env.PUBLIC_URL+"/images/ss.png"} ><i className="fa fa-fw fa-eye"></i></a>
                    <span>Web Development</span>
                    <h6>
                        <a href="#/Contact-us" style={{border:"none",color: "white",fontsize: "14px"}}>Click Here For Web Development</a>
                    </h6>
                </div>
            </div>

            <div data-tilt className="single_item portfolio-small-img digital-marketing">
               
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"/images/111oo.png"} alt="Img"></img>
                <div className="single_item_content">
                    <a href={process.env.PUBLIC_URL+"/images/111oo.png"}><i className="fa fa-fw fa-eye"></i></a>
                    <span>Web Development</span>
                    <h6>
                        <a href="#/Contact-us"style={{border:"none",color: "white",fontsize: "14px"}}>Click Here For Web Development</a>
                    </h6>
                </div>
            </div>
           
            <div data-tilt className="single_item portfolio-small-img web-developpment">
               
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"/images/analysis-1841158_640.png"} alt="Img"></img>
                <div className="single_item_content">
                    <a href={process.env.PUBLIC_URL+"/images/analysis-1841158_640.png"}><i className="fa fa-fw fa-eye"></i></a>
                    <span>Digital Marketing</span>
                    <h6>
                        <a href="#/Contact-us" style={{border:"none",color: "white",fontsize: "14px"}}>Click Here For Digital Marketing</a>
                    </h6>
                </div>
            </div>

         
            <div data-tilt className="single_item portfolio-small-img design">
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"/images/Tonoonborsari.png"} alt="Img"></img>
                <div className="single_item_content">
                    <a href={process.env.PUBLIC_URL+"/images/Tonoonborsari.png"}><i className="fa fa-fw fa-eye"></i></a>
                    <span> Web Development</span>
                    <h6>
                        <a href="#/Contact-us" style={{border:"none",color: "white",fontsize: "14px"}}>Click Here For Web Development</a>
                    </h6>
                </div>
            </div>
     
            <div data-tilt className="single_item portfolio-small-img web-developpment">
             
                    <img  className="img-fluid" src={process.env.PUBLIC_URL+"/images/social-media-1795578_640.png"} alt="Img">
                    </img>              
                      <div className="single_item_content">
                    <a href={process.env.PUBLIC_URL+"/images/social-media-1795578_640.png"}><i className="fa fa-fw fa-eye"></i></a>
                    <span>Digital Marketing</span>
                    <h6>
                        <a href="#/Contact-us"style={{border:"none",color: "white",fontsize: "14px"}}>Click Here For Digital Marketing</a>
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<Bottom></Bottom>
</div>
    )
    
}
export default Home;
