import React from "react";
 function Bottom(){
     return(
         <div>
         <div style={{padding:"50px"}}>
     
    
    <div className="test-gg col-md-12">
    <div className="col-md-12" style={{textAlign:"center"}}>
        <h2 style={{color: "#000",fontsize: "26px",fontWeight:"300",textalign:"center",position: "relative",margin: "30px 0 60px"}}>
            See What <b style={{borderBottom:"solid", borderColor:"#aa1522"}}>Our Customers</b> Say About Us
        </h2>
    </div>
    </div>
    <div className="container-fluid" style={{minheight:"420px",paddingtop:"40px"}}>
        <div className="row">
            <div className="col-md-12">

                <div id="myCarousel" className="carousel slide" data-ride="carousel" style={{padding:"0"}}>
                    <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="item carousel-item active container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial">
                                            Superb services! Zutech has fulfilled business dreams with flying colors. The team was highly professional and was readily available to put light on my queries. Thank you for providing seamless support throughout the project.
                                                                                </div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Lovekesh Kaushal</b></div>

                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-half-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial">
                                            I was quite hesitant to trust any company, but trust me choosing Zutech was the best decision for my business. The team paid key attention to the details and provided me with robust web solutions. Looking forward to working again with you guys!
                                                                                </div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Simranjeet Singh</b></div>

                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item carousel-item">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial">
                                            It is always a delight to work with a company that understands you well. Zutech followed an agile business approach where they took care of every detail. I got on-time delivery of my project. Kudos to the team!
                                                                                </div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Kavita Sharma</b></div>

                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial">
                                            It was my new venture and my expectations were quite high, and I am glad that I got associated with a talented team that assured everything works fine and delivered quality products right on time.
                                                                                </div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Gurmeet Narwal</b></div>

                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item carousel-item">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial">
                                            5-star services! Zutech offered me premium web development services that included everything from web design to content and later marketing. I got all these services within my budget! Overall it was a phenomenal experience.
                                                                                </div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Anurag Singla</b></div>

                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="testimonial-wrapper">
                                        <div className="testimonial"> I have been working with Zutech for over two years and I my questions are always answered quickly and service is prompt. I highly recommend their services. "Excellent and professional.</div>
                                        <div className="media">

                                            <div className="media-body">
                                                <div className="overview">
                                                    <div className="name"><b>Ishika Saini</b></div>
                                                    <div className="star-rating">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                                            <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>




         </div>
         <footer className="site-footer-2">
        <div className="container footer-call-to-action wow bounceInDown" data-wow-duration="2.5s">

            <div className="row" style={{paddingLeft:"30px"}}>
                <div className="col-md-9 align-self-center">
                    <span style={{color: "black"}}>IMPRESSED?</span>
                    <h2>Are you interested to work with us?</h2>
                </div>
                <div className="col-md-3 align-self-center">
                    <div className="zutech-btn">
                        <a href="#/Contact-us">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">

            <div className="row">
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-widget widget-newslatter">
                        <h5 className="widget-title">About Us</h5>
                        <p>Zutech is a comprehensive and unique IT company operating in Melbourne, Australia. The company aim is to deliver award winning IT services. We have grown as a leader in offering IT services to small, medium and corporate business.</p>
                    </div>
                </div>


                <div className="col-lg-3 col-sm-6">
                    <div className="footer-widget">
                        <h5 className="widget-title">Services</h5>
                        <ul>
                            <li><a href="#/Digital-Marketing">Digital marketing</a></li>
                            <li><a href="#/Web-Devlopment">Web Development</a></li>
                            <li><a href="#/IT-Services">IT Services</a></li>
                            <li>
                                <a href="#/Search-Engine-Optimisation">
                                    Search Engine Optimisation
                                </a>
                            </li>
                            <li><a href="#/Web-Designing">Web Designing</a></li>
                            <li><a href="#/Content-Writing">Content Writing</a></li>

                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-widget">
                        <h5 className="widget-title">Quick links</h5>
                        <ul>
                            <li><a href="">Home</a></li>
                            <li><a href="#/Contact-us">Contact us</a></li>
                            <li><a href="#/About-us">About Us </a></li>
                            <li><a href="">Privacy policy</a></li>


                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-widget">
                        <h5>Contact Us</h5>
                        <p>Suite 10, Level 7/2 Queen St
                            <br></br>
2 Queen St, Melbourne VIC 3000, Australia</p>
                        <ul>
                            <li><i className="fa fa-phone"></i>+61 449 580 001</li>
                            <li><i className="fa fa-envelope-o "></i><a href="mailto:info@zutech.com.au"> info@zutech.com.au</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-12" style={{height: "40px",textAlign:"center",color: "#fff",borderTop: "1px solid rgba(255, 255, 255, 0.3)", marginTop: "50px",paddingTop:"20px"}}>

                    <div className="bnr-social">
                        <ul className="list-inline footer-icon">
                            <li className="list-inline-item"><a href="https://www.facebook.com/Zutechcorp" style={{color:"white", fontSize:"29px"}}><i className="fa fa-fw fa-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" style={{color:"white", fontSize:"29px"}}><i className="fa fa-fw fa-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.linkedin.com/company/zutech-corp/" style={{color:"white", fontSize:"29px"}}><i className="fa fa-fw fa-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/zutech_au/"style={{color:"white", fontSize:"29px"}}><i className="fa fa-fw fa-instagram"></i></a></li>

                        </ul>
                    </div>

                </div>
                <div className="col-md-12">
                    <div className="footer-copyright">
                        <p> Copyrighs  Zutech Corp. 2021</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
         </div>
     )
 }
 export default Bottom;