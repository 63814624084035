import React from "react";
import Bottom from "./Bottom.jsx";


function About() {
    return (
        <div>
        <title>About Us | Zutech</title>

            <section className="breadcrumb-bnr" style={{ height: "377px;" }}>

                <img src={process.env.PUBLIC_URL+"./images/bread-bnr.png"} alt="Banner"></img>
                <div className="breadcrumb-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>About Us </h1>
                                <ul className="list-inline">
                                    <li className="list-inline-item"><a href="#/Home">Home</a></li>
                                    <li className="list-inline-item">/</li>
                                    <li className="list-inline-item"><a href="#">About Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="container">
                <div className="row">

                    <div className="col-md-6 wow fadeInRight" data-wow-duration="2.5s">
                        <div className="space d-block d-sm-none" style={{ height: "60px" }}></div>
                        <div className="section-title">
                            
                            <h1>Creative Digital Marketing Solutions to Kick Start Your Business Now

 <span> Digital Marketing | </span>  Web Designing | <span> Web Development | Branding</span></h1>

                            <img src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
                        </div>
                        <p>Zutech is a full-service marketing agency based in Melbourne, Australia. While there are thousands of digital marketing agencies, only a few are comprehensive and exceptionally passionate and creative; offering full-scope digital marketing services, catering to your unique needs and budget. Our areas of expertise include digital marketing, web designing, IT services, search engine optimization, content writing, and web development. 
<br></br>
Our skilled and experienced team is passionate about bringing your imagination to real life, dedicated to building brands with passion and purpose. Our perfect blend of data-driven strategies and one-of-a-kind approaches will help you build a brand people love and trust. We believe in the transformative power of marketing and design to simplify communications, improve experiences, and engage and inspire people everywhere. 
</p>


                        <div className=" space" style={{height: "30px"}}></div>

                        <div className="zutech-btn">
                            <a href="#/Contact-us">Contact Us</a>
                    </div>
                </div>
                <div className="col-md-6 text-center wow fadeInLeft floating">
                   
                        <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"./images/busmeeting1.jpg"} alt="img"></img>
</div>
                </div>
            </div>


            <div class="space" style={{height: "110px"}}></div>



<div class="container">
    <div class="row">
        <div class="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img class="img-fluid img-shadow"
                src={process.env.PUBLIC_URL+"./images/workplace.jpg"} alt="img"></img>
        </div>
        <div class="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div class="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div class="section-title">
                
                <h1>Our <span>commitment: </span> </h1>
               
                    <img src={process.env.PUBLIC_URL+"./dot-bluecolor.png"} alt="img"></img>
            </div>
            <p>
            Whether you want to boost website traffic, build brand awareness, generate more leads, or gain additional exposure in a crowded consumer marketplace, it’s our genuine commitment to your business’s success that makes Zutech a trusted marketing partner. 
          <br></br>  <strong>Our Values </strong> <br></br> 
Finding new ways to bring an extra creative flair to all your projects <br></br> 
Striving to do everything with integrity and honesty <br></br> 
Continually enhancing our expertise and services <br></br> 
Maintaining a positive, friendly, and happy work environment  <br></br> 
 <br></br> 
<strong>Let’s Work Together!</strong>
</p>


            <div class="space" style={{height: "30px"}}></div>

            <div class="zutech-btn">
                <a href="#/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>
<div class="space" style={{height:"120px"}}></div>
<Bottom></Bottom>
  </div>
    )
}
export default About;
