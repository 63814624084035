import "./App.css";
import "./style.css";
import { Route,Link, HashRouter} from 'react-router-dom';
import Home from './Home.jsx';
import About from './About.jsx';
import Content from './Content.jsx';
import Digital from './Digital.jsx';
import ITServices from './ITServices.jsx';
import SearchEngine from './SearchEngine.jsx';
import Webdevlopment from './Webdevlopment.jsx';
import Designing from "./Designing.jsx";
import Contact from "./Contact.jsx";
import React from 'react';
import * as ReactBootstrap from "react-bootstrap";




function App() {
  return (
    <div>
     
     
     <HashRouter>
       <div className="container">
     <ReactBootstrap.Navbar collapseOnSelect expand="lg" variant="dark" >
    <ReactBootstrap.Navbar.Brand href="#home">
    <img src={process.env.PUBLIC_URL+"./images/zutechlogo.png"} style={{width:100, marginTop: -7,}} />
            </ReactBootstrap.Navbar.Brand>
    <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
  
      <ReactBootstrap.Nav>
      <ReactBootstrap.Nav> <Link to="/">Home</Link></ReactBootstrap.Nav>
      <ReactBootstrap.Nav> <Link to="/About-us">About us</Link></ReactBootstrap.Nav>
      {/* <ReactBootstrap.Nav> <Link to="/Content-Writing">Content Writing</Link></ReactBootstrap.Nav> */}
      {/* <ReactBootstrap.Nav> <Link to="/Digital-Marketing">Digital Marketing</Link></ReactBootstrap.Nav> */}
      {/* <ReactBootstrap.Nav> <Link to="/Web-Designing">Web Designing</Link></ReactBootstrap.Nav> */}
      {/* <ReactBootstrap.Nav> <Link to="/IT-Services" > IT Services </Link></ReactBootstrap.Nav> */}
      {/* <ReactBootstrap.Nav> <Link to="/Search-Engine-Optimisation">Search Engine Optimisation</Link></ReactBootstrap.Nav> */}
       <ReactBootstrap.NavDropdown title="IT Services" id="basic-nav-dropdown">
       <Link className="dropdown-item" to="/"><span id="white">Office 365</span></Link>
      <Link className="dropdown-item" to="/"><span id="white">Google Workplace</span></Link>
      <Link className="dropdown-item" to="/IT-Services"><span id="white">SSL Installation</span></Link>
      <Link className="dropdown-item" to="/"><span id="white">Sharepoint</span></Link>
      <Link className="dropdown-item" to="/"><span id="white">Powerautomate</span></Link>
      <Link className="dropdown-item" to="/"><span id="white">VPS (Virtual Private Server) Setup</span></Link>
            </ReactBootstrap.NavDropdown>
            <ReactBootstrap.NavDropdown title="Web Development" id="basic-nav-dropdown">
            <Link className="dropdown-item" to="/Web-Designing">  <span id="white">Web Designing</span></Link>
               <Link className="dropdown-item" to="/"><span id="white">Web Maintenance</span></Link>
               <Link className="dropdown-item" to="/"><span id="white">Web Development</span></Link>
               <Link className="dropdown-item" to="/"><span id="white">Graphic Designing</span></Link>
               <Link className="dropdown-item" to="/"><span id="white">Database Builder and CRM</span></Link>
            </ReactBootstrap.NavDropdown>
          
            <ReactBootstrap.NavDropdown title="Digital Marketing " id="basic-nav-dropdown">
            <Link className="dropdown-item" to="/Digital-Marketing">  <span id="white">Facebook Marketing</span></Link>
            <Link className="dropdown-item" to="/Digital-Marketing"><span id="white">Email Marketing </span></Link>
            <Link className="dropdown-item" to="/"> <span id="white">SMS Marketing </span></Link>
            <Link className="dropdown-item" to="/Search-Engine-Optimisation"> <span id="white">Search Engine Optimisation (SEO) </span></Link>
            <Link className="dropdown-item" to="/"> <span id="white">Google Ads </span></Link>
            <Link className="dropdown-item" to="/Content-Writing"> <span id="white">Content Writing</span></Link>
               </ReactBootstrap.NavDropdown>
               <ReactBootstrap.Nav> <Link to="/Contact-us">Contact us</Link></ReactBootstrap.Nav>
               
       </ReactBootstrap.Nav>
    </ReactBootstrap.Navbar.Collapse>
  </ReactBootstrap.Navbar>
  </div>
       <Route exact path="/" component={Home}></Route>
       <Route path="/Home" component={Home}></Route>
       <Route path="/About-us" component={About}></Route>
       <Route path="/Content-Writing" component={Content}></Route>
       <Route path="/Digital-Marketing" component={Digital}></Route>
       <Route path="/IT-Services" component={ITServices}></Route>
       <Route path="/Search-Engine-Optimisation" component={SearchEngine}></Route>
       <Route path="/Web-Devlopment" component={Webdevlopment}></Route>
       <Route path="/Web-Designing" component={Designing}></Route>
       <Route path="/Contact-us" component={Contact}></Route>


     </HashRouter>
   
    </div>
  );
}

export default App;
