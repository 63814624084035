import React from "react";
import Bottom from "./Bottom.jsx";


function Digital (){
return(
    <div>
                <title>Digital Marketing | Zutech</title>

<section className="breadcrumb-bnr">
  
        <img src={process.env.PUBLIC_URL+"./images/wordpress-development.png"} alt="Banner"></img>
    <div className="breadcrumb-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>Digital Marketing </h1>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="#/Home">Home</a></li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item"><a href="#">Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>





<div className="container">
    <div className="row">
        <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"./images/Digital-Marketing.png"} alt="img"></img>
        </div>
        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div className="section-title">
            
                <h1>Let Us Help You   <span> Bring Out Your  </span>Business to the World</h1>
                
                    <img  src={process.env.PUBLIC_URL+"./images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <p>Digital Marketing is the new generation marketing strategy where you can take advantage of the Internet and electronic devices to upgrade your business standards. It connects all the online modes of communication like email marketing, social media marketing, search engines and websites to reach the designated audience looking for products and services ultimately.<br></br>We let your business flourish in the online sphere with ethical marketing plan so that you can utilize the optimal benefit from the facilities available on the Internet. We specialize in making cost-effective direct marketing plans considering your demands to extract beneficial outcomes for your business in the end. Moreover, we support your business by considering real-time dynamics in the industry and consequently enhance your business model at a broader level.</p>



            <div className="space" style={{height: "30px"}}></div>

            <div className="zutech-btn">
                <a href="#/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>




<div className="space" style={{height: "115px"}}></div>











<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img src={process.env.PUBLIC_URL+"./zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height:"40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                <i className="fa fa-cogs"></i> 

                    <h5> SEO Services</h5>
                    <p className="websites">
                        Improve your site’s user experience and build brand credibility with our SEO services. From organic and local SEO to off-page and on-page SEO, we provide top-notch SEO services across a range of industries. Our SEO team at Zutech will help you get on top of the Google SERPs so you can be found by your targeted audience seamlessly. 
                    </p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                <i className="fa fa-bookmark"></i> 
                                    <h5>Pay Per Click</h5>
                    <p>Reach audiences cost-effectively, get instant traffic, drive warm leads, and boost ROI with our Pay Per Click services. We listen to your end advertising goals which will help us to manage your campaign to the best of our ability. Our years of experience and strategists’ expertise allow us to make the most of each dollar you spend.</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                    <i className="fa fa-edit"></i> 
                    <h5> Content Writing</h5>
                    <p className="websites">Our qualified and experienced writers deliver unique, engaging, and compelling content that’s keyword-optimized for SEO. Whether it’s an article, blog post, social media posts, product description, press release, or another type of content, we follow SEO best practices so that your site can outperform its competition.</p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                <i className="fa fa-users"></i> 

                    <h5>Social Media Marketing</h5>
                    <p>Our team of social media enthusiasts can create and manage top-performing social media campaigns on networks such as Facebook, Instagram, Linkedin, Twitter, and Pinterest. Our custom social media marketing plans will help you build brand awareness, generate more traffic, improve brand loyalty, gain market insights, and ultimately generate more revenue. </p>
                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
            <a href="#/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minHeight:"420px"}}>
                    <i class="fa fa-globe"></i>
                    <h5>Google Ads</h5>
                    <p>We perform an effective utilization of Google Ads to create the powerful advertisement and efficient marketing campaigns which in turn stimulate the target Ad platform to provide you best rate possible.</p>
                </div>
            </a>
        </div>
    </div>
</div>

<Bottom></Bottom>

</div>


)
}
export default Digital;