import React from "react";
import Bottom from "./Bottom.jsx";

function SearchEngine(){
    return(
        <div>
            
            <title>Search Engine Optimisation | Zutech</title>
<section className="breadcrumb-bnr">
        <img src={process.env.PUBLIC_URL+"/images/wordpress-development.png"} alt="Banner"></img>
    <div className="breadcrumb-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>Search Engine Optimisation  </h1>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="/Home">Home</a></li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item"><a href="#">Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>



<div className="container">
    <div className="row">
        <div className="col-lg-6 col-sm-12 text-center wow fadeInLeft floating">
           
                <img className="img-fluid img-shadow" src={process.env.PUBLIC_URL+"/images/search-engine.png"} alt="img"></img>
        </div>
        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 wow fadeInRight" data-wow-duration="2.5s">
            <div className="space d-block d-sm-none" style={{height: "60px"}}></div>
            <div className="section-title">
            
                <h1>Search    <span> Engine </span>Optimisation</h1>
                
                    <img  src={process.env.PUBLIC_URL+"/images/dot-bluecolor.png"} alt="img"></img>
            </div>
            <p>
            Want to increase your website traffic? If you are looking for serious business growth in the online world, then hire our dedicated SEO services. At Zutech, we provide dedicated SEO services that can help make your business stand out among its competitors. Our expert SEO services can help you drive direct traffic to the site and increase your overall business rankings. We provide a full set of integrated SEO services that can help you drive your company’s digital growth. Wait no more and get a free SEO audit to know where your business stands.
            </p>



            <div className="space" style={{height: "30px"}}></div>

            <div className="zutech-btn">
                <a href="/Contact-us">Contact Us</a>
            </div>
        </div>
    </div>
</div>



<div classname="space" style={{height: "115px"}}></div>





<div className="container">
    <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="section-title text-center">
                <h1>Services we provide</h1>
               
                    <img src={process.env.PUBLIC_URL+"/zutech/dot-bluecolor.png"} alt="img"></img>
            </div>
            <div className="space" style={{height: "40px"}}></div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
            <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                    <i className="fa fa-share-square"></i> 
                    <h5>On-Page</h5>
                    <p classname="websites">
                    Do you wish to see your business rank on the first page of the search engines? Our on-page services can help you gain valuable traffic to your site. Our 360-degree approach to on-page SEO includes - meta descriptions, meta titles, on-page copies, image alt attributes, internal link structures, schema markups, and more. Our SEO services come in all sizes and shapes and can easily be custom as per your business needs.</p></div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="1.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                     <i className="fa fa-tasks"></i> 
                    <h5>Off-Page</h5>
                    <p classname="websites">   
                    Are you tired of repeated online efforts to rank your website? Our off-page services can help in increasing your website authority, ranking, and visibility that will help in generating leads. We follow strategic link-building activities that will boost your online business presence and drive website traffic. Wish to discuss more site optimization, organic traffic, conversion, and more? Get in touch now!</p> 

                </div>
            </a>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-duration="2.5s" >
        <a href="/Contact-us" style={{color: "#212529"}}>
                <div className="service-item" style={{minheight:"420px"}}>
                <i class="fa fa-edit"></i>
                    <h5>                        Blogs and Content Marketing
</h5>
                    <p classname="websites">
                    Searching for ways to reach your target audiences? Choose our blog and content marketing services. SEO content acts as fuel in driving new visitors to the site. From keyword research to the right execution of the content, our comprehensive view can help in driving relevant traffic to your site and boost your search engine rankings. So, flatter your visitors with compelling content and woo them with your dedicated services.</p>  
                </div>
            </a>
        </div>
       </div>
</div>





<Bottom></Bottom>




        </div>
    )
}
export default SearchEngine;